import React from 'react'
import withLandingNamespaces from '~/hoc/withLandingNamespaces'
import { getStaticFilePath } from '../../../utils/helpers'

@withLandingNamespaces()
export default class SecondSection extends React.Component {
  render () {
    const { t } = this.props

    const items = [
      { id: 'item_1', image: 'item_1.png' },
      { id: 'item_2', image: 'item_2.png' },
      { id: 'item_3', image: 'item_3.png' },
      { id: 'item_4', image: 'item_4.png' }
    ]
    return (
      <div className='gw-second-section'>
        <h1
          className='gw-second-section__title'
          dangerouslySetInnerHTML={{ __html: t('second_section.title') }}
        />
        <div className='gw-second-section-items'>
          {items.map(item =>
            <div className='gw-second-section-items-item' key={item.id}>
              <img
                className='gw-second-section-items-item__image'
                src={getStaticFilePath(`assets/landing-gw/second-section/${item.image}`)}
                draggable={false}
              />
              <h1
                className='gw-second-section-items-item__title'
                dangerouslySetInnerHTML={{ __html: t(`second_section.items.${item.id}.heading`) }}
              />
              <span
                className='gw-second-section-items-item__description'
                dangerouslySetInnerHTML={{ __html: t(`second_section.items.${item.id}.description`) }}
              />
            </div>)}
        </div>
      </div>
    )
  }
}
