import React from 'react'
import bemCx from 'bem-modifiers'

import withLandingNamespaces from '~/hoc/withLandingNamespaces'

import HeroVoucherContent from '~/components/Festival/common/HeroVoucherContent'
import HeroFilters from '../HeroFilters/HeroFiltersContainer'
import moment from 'moment'

import { FESTIVAL_EDITION_STATES } from '~/utils/consts'
import { getStaticFilePath } from '../../../../utils/helpers'

const TIMEOUT_DURATION = 3000

const Content = ({
  isVoucher,
  isPresale,
  festivalName,
  festivalData,
  regions,
  heroWithPrice,
  heroWithDate,
  heroWithTag,
  buttonLabel,
  buttonLabelSecond,
  t
}) => {

  const { endsAt, startsAt } = festivalData || {}

  const hasDates = endsAt && startsAt

  return (
    isVoucher
      ? <HeroVoucherContent
        festivalName={festivalName}
        festivalData={festivalData}
        regions={regions}
      />
      : <div className='hero-section__text'>
        <div>
          <span
            className='hero-section__event'
          >
            {hasDates &&
              t('hero_section.event_dates', {
                dateStart: moment.parseZone(startsAt).format('DD.MM'),
                dateEnd: moment.parseZone(endsAt).format('DD.MM'),
              })}
          </span>
        </div>
        <h1
          className='hero-section__title'
          dangerouslySetInnerHTML={{ __html: t('hero_section.title') }}
        />
        <HeroFilters
          locationModifiers={['geolocation', 'hero', 'hero-gw']}
          peopleModifiers={['people', 'hero-gw']}
          buttonModifiers={['gw', 'sales']}
          buttonLabel={buttonLabel}
          buttonLabelSecond={buttonLabelSecond}
          festivalData={festivalData}
          labelUpper={t('hero_section.subtitle')}
        />
        {heroWithPrice && festivalData && <div className='gw-hero-content__info'>
          <div>
            <span className='gw-hero-content__price'>{t('hero_section.only', { price: festivalData.price })}</span>
          </div>
          <span className='gw-hero-content__gratis'>{t('hero_section.reservation_fee')}</span>
        </div>}
      </div>
  )
}

@withLandingNamespaces()
export default class HeroSection extends React.Component {

  componentDidMount () {
    this.initSwiper()
    this.changeAdSlide()
  }

  componentWillUnmount () {
    this.swiper && this.swiper.destroy()
    clearInterval(this.adInterval)
  }

  initSwiper = () => {
    this.swiper && this.swiper.init()
  }

  updateSwiper = () => {
    this.swiper && this.swiper.update()
  }

  changeAdSlide = () => {
    const { slides } = this.props
    let i = 0
    const lastSlide = slides ? slides.length - 1 : 0
    this.adInterval = setInterval(() => {
      if (i < lastSlide) {
        this.updateSwiper()
        this.swiper && this.swiper.slideNext()
        i++
      } else {
        clearInterval(this.adInterval)
      }
    }, TIMEOUT_DURATION)
  }

  get priceSentence () {
    const { festivalData, t } = this.props
    const reservationPrice = festivalData ? festivalData.price : 0
    const price = reservationPrice.toFixed(0)

    return (
      <>
        <span className='hero-section__only-text'>{t('hero_section.only')}</span>
        <span>{price}</span>
        <span>{t('hero_section.per_person')}</span>
      </>
    )
  }

  get buttonLabel () {
    const { festivalData, t } = this.props

    return festivalData === null
      ? t('hero_section.no_festival_button')
      : t('take_part.button')
  }

  get buttonLabelSecond () {
    const { festivalData, t } = this.props
    const reservationPrice = festivalData ? festivalData.price : 0
    const price = reservationPrice.toFixed(0)

    return festivalData === null
      ? t('hero_section.no_festival_button')
      : t('take_part.button_second', { price })
  }

  render () {
    const {
      festivalData,
      regions,
      festivalEditionState,
      festivalName,
      heroWithDate = true,
      heroWithPrice = true,
      heroWithTag,
      t
    } = this.props
    const isVoucher = festivalEditionState === FESTIVAL_EDITION_STATES.VOUCHER
    const isPresale = festivalEditionState === FESTIVAL_EDITION_STATES.PRESALE

    return (
      <section className={bemCx('hero-section', [{ 'voucher': isVoucher }, festivalName])}>
        <div className='hero-section__content'>
          <Content
            festivalName={festivalName}
            festivalData={festivalData}
            regions={regions}
            isVoucher={isVoucher}
            isPresale={isPresale}
            heroWithPrice={heroWithPrice}
            heroWithDate={heroWithDate}
            heroWithTag={heroWithTag}
            buttonLabel={this.buttonLabel}
            buttonLabelSecond={this.buttonLabelSecond}
            t={t}
          />
        </div>
        <div className='hero-section__swiper'>
          <img
            className='hero-section__swiper-image'
            src={getStaticFilePath('assets/landing-gw/gin-guy.png')}
            draggable={false}
          />
        </div>
      </section>
    )
  }
}
