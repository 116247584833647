import React from 'react'

import Footer from '~/components/Footer/FooterGql'
import HeroSection from '~/components/Festival/gw/HeroSection/HeroSectionGql'
// import HeroBanner from '~/components/HeroBanner'
import TakePartSection from './TakePartSection/TakePartSectionGql'
import LogosSection from '~/components/LogosSection/LogosSectionGql'
import SecondSection from './SecondSection'
import ThirdSection from './ThirdSection'
// import FourthSection from './FourthSection'
import FifthSection from './FifthSection'
// import SixthSection from './SixthSection'

export default class Index extends React.Component {
  render () {
    // const { isSale } = this.props

    return <div>
      <div className='container-red'>
        <HeroSection
          heroWithTag
        />
      </div>
      <div className='container-white' >
        <SecondSection />
      </div>
      <hr className='gw-second-section__divider' />
      <div className='container-white'>
        <ThirdSection />
      </div>
      {/* <div className='container-brown'>
        <FourthSection />
      </div> */}
      <div className='container-red'>
        <FifthSection />
      </div>
      {/* <div className='container-white'>
        <SixthSection />
      </div> */}
      <div className='container-white'>
        <LogosSection loadImmediately />
      </div>
      <TakePartSection modifiers='index' withRespect={false} />
      <Footer />
    </div>
  }
}
