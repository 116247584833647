import React from 'react'
import bemCx from 'bem-modifiers'
import { withNamespaces } from '>/i18n'

import HeroFilters from '../HeroFilters/HeroFiltersContainer'
import { getStaticFilePath } from '../../../../utils/helpers'

@withNamespaces('homepage-gw')
export default class TakePartSection extends React.Component {

  get buttonLabel () {
    const { t } = this.props

    return t('take_part.button')
  }

  get buttonLabelSecond () {
    const { festivalData, t } = this.props
    const reservationPrice = festivalData ? festivalData.price : 0
    const price = reservationPrice.toFixed(0)

    return t('take_part.button_second', { price })
  }

  get isMyReservationPage () {
    return this.props.router.pathname === '/my-reservations'
  }

  get buttonModifiers () {
    return ['gw-black']
  }

  render () {
    const { modifiers, festivalData, festivalName, t } = this.props

    return (
      <div className={bemCx('take-part-section', modifiers)}>
        <div className='take-part-section__content container'>
          <img
            className='take-part-section__logo'
            src={getStaticFilePath('assets/logo/logo-festival-gw.svg')}
            draggable={false}
          />
          <span
            className='take-part-section__description'
            dangerouslySetInnerHTML={{ __html: t('take_part.description') }}
          />
          <HeroFilters
            locationModifiers={['geolocation', 'hero', 'white-gw']}
            alternativePrefix
            peopleModifiers={['people', 'white-gw']}
            buttonModifiers={this.buttonModifiers}
            buttonLabel={this.buttonLabel}
            buttonLabelSecond={this.buttonLabelSecond}
            iconRight={festivalName === 'rw' ? 'arrow-long' : undefined}
            festivalData={festivalData}
            mainClass={'gw-black'}
          />
          <span className='take-part-section__warning'>
            {t('take_part.warning')}
          </span>
        </div>
      </div>
    )
  }
}
