import React from 'react'
import withLandingNamespaces from '~/hoc/withLandingNamespaces'
import { getStaticFilePath } from '../../../utils/helpers'

@withLandingNamespaces()
export default class FifthSection extends React.Component {
  render () {
    const { t } = this.props

    return (
      <div>
        <div className='gw-fifth-section'>
          <div className='gw-fifth-section__content'>
            {/* <span className='gw-fifth-section__text-1'>{t('fifth_section.text_1')}</span> */ }
            <div className='gw-fifth-section-top'>
              <h1
                className='gw-fifth-section-top__text-2'
                dangerouslySetInnerHTML={{ __html: t('fifth_section.text_2') }}
              />
            </div>
            <div className='gw-fifth-section-main'>
              <div className='gw-fifth-section-main-left'>
                <span
                  className='gw-fifth-section-main-left__text-3'
                  dangerouslySetInnerHTML={{ __html: t('fifth_section.text_3') }}
                />
                <div className='gw-fifth-section-main-left-second'>
                  <span
                    className='gw-fifth-section-main-left-second__text-4'
                    dangerouslySetInnerHTML={{ __html: t('fifth_section.text_4') }}
                  />
                  <span
                    className='gw-fifth-section-main-left-second__text-5'
                    dangerouslySetInnerHTML={{ __html: t('fifth_section.text_5') }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='gw-fifth-section__image'>
            <img
              className='gw-fifth-section-main-right__image'
              src={getStaticFilePath('assets/landing-gw/fifth-section/image2.jpg')}
              draggable={false} />
          </div>
        </div>
      </div>
    )
  }
}
