import React from 'react'
import withLandingNamespaces from '~/hoc/withLandingNamespaces'

@withLandingNamespaces()
export default class ThirdSection extends React.Component {
  render () {
    const { t } = this.props

    return (
      <div className='gw-third-section'>
        <div className='gw-third-section-top'>
          <h1
            className='gw-third-section-top__title'
            dangerouslySetInnerHTML={{ __html: t('third_section.title') }}
          />
          <span
            className='gw-third-section-top__description'
            dangerouslySetInnerHTML={{ __html: t('third_section.description') }}
          />
        </div>
        <div className='gw-third-section-top-images'>
          <img
            className='gw-third-section-top-images__image-1'
            draggable={false}
          />
          <img
            className='gw-third-section-top-images__image-2'
            draggable={false}
          />
        </div>
      </div>
    )
  }
}
